import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="85c670d292cecd6831a811f6b238d0b166ff7f8d"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werklast
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/recepten-voor-een-evenwichtige-voeding/">
                      Recepten
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Deze video laat zien hoe Imodium<sup>®</sup> helpt om je
                  darmen terug in hun natuurlijke balans te brengen.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-14">
            <h1>Tips voor de behandeling van de symptomen van diarree</h1>
            <div className="img">
              <img
                src="/assets/files/pages/durchfallmythen.jpeg"
                width="701"
                height="289"
                alt="Tips voor behandeling"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Tips voor de behandeling van diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  Over de behandeling van diarree zijn er nog veel misverstanden
                  en onzekerheden.&nbsp;
                </p>
                <p>Lees hier enkele belangrijke tips:</p>
              </div>
            </div>
            <h3>Tip 1: Voorkom uitdroging</h3>
            <p>
              De belangrijkste eerste stap in geval van diarree is om ervoor te
              zorgen dat er voldoende vocht wordt ingenomen. Zorg ervoor dat je
              voldoende water drinkt, best 2 tot 3 liter per dag. Eventueel kan
              het nuttig zijn om ook extra elektrolyten in te nemen. Een
              kant-en-klare samenstelling vind je in de apotheek.
            </p>
            <h3>Tip 2: Wacht niet met de behandeling van diaree</h3>
            <p>
              Veel mensen geloven dat <strong>diarree</strong> nuttig is om de
              bacteriën die de diarree veroorzaken uit het lichaam weg te
              spoelen. De ziekteverwekkers worden echter niet bestreden door de
              diarree, maar wel door het lichaamseigen immuunsysteem. De diarree
              is alleen maar een symptoom. Vaak wordt diarree zelfs niet eens
              door bacteriën, maar door andere,{" "}
              <a href="/feiten-over-diarree/stress-en-diarree/">
                niet besmettelijke oorzaken
              </a>
              &nbsp;uitgelokt. Of bacteriën er nu wel of niet bij betrokken zijn,{" "} 
			  <a href="/imodium-behandelen-diarree/imodium-instant/">
                IMODIUM® Instant orodispergeerbare tabletten
              </a> 
			  &nbsp;of{" "}
              <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                IMODIUM® Capsules
              </a>{" "}
			  kan de symptomen van diarree snel verlichten, terwijl je eigen
              immuunsysteem de infectie verder bestrijdt. Daardoor voel je je
              beter en wordt je normale dagelijkse leven niet overhoop gegooid.
            </p>
            <h3>
              Tip 3: Diarree bij kinderen: een snelle behandeling is uiterst
              belangrijk
            </h3>
            <p>
              Diarree onttrekt waardevol vocht en voedingsstoffen aan je
              lichaam. Kinderen kunnen dit vochttekort minder goed verdragen dan
              volwassenen. Daarom is het belangrijk om bij{" "}
              <strong>diarree bij kinderen</strong> hen het vocht snel aan te
              vullen en de <strong>diarree te stoppen</strong>. Bij kinderen
              vanaf 6 jaar mogen de symptomen van diarree met{" "}
              <a href="/imodium-behandelen-diarree/imodium-instant/">
                IMODIUM® Instant orodispergeerbare tabletten
              </a>
              &nbsp;of{" "}
              <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                IMODIUM® Capsules
              </a>{" "}
              behandeld worden. In de apotheek vind je daarnaast ook nog
              kant-en-klare elekrolytenmengsels&nbsp; voor behandeling van
              diarree bij kinderen.&nbsp; Deze elektrolytenmengsels zorgen
              ervoor dat&nbsp; de verloren elektrolyten (mineralen) en suikers
              opnieuw aangevuld worden. Als het probleem aanhoudt, moet de arts
              geraadpleegd worden. Zeker met baby’s of hele kleine kinderen ga
              je best langs de arts in geval van diarree. Lees ook de informatie
              over <a href="/diarree-bij-kinderen/">diarree bij kinderen</a>.
            </p>
            <h3>
              Tip 4: Zorg ervoor dat de overmatige darmactiviteit snel terug
              vermindert
            </h3>
            <p>
              Het gebruik van IMODIUM® zal bijdragen om de darmactiviteit te verminderen zodat je stoelgang weer vaster wordt en je diarree vermindert. Bedenk wel: door de diarree is je
              darm vaak helemaal geledigd. Het kan dus één of twee dagen duren,
              voordat hij weer gevuld is.&nbsp; Daardoor kan je de indruk
              krijgen dat je geconstipeerd bent, omdat je in deze periode geen
              stoelgang hebt.&nbsp;
            </p>
            <h3>Tip 5: Diarree kan besmettelijk zijn</h3>
            <p>
              Aangezien diarree door{" "}
              <a href="/feiten-over-diarree/buikgriep/">
                besmettelijke ziektekiemen (bacteriën of virussen, bv. bij
                gastro-enteritis)
              </a>
              &nbsp;veroorzaakt kan worden, is het mogelijk om iemand te
              besmetten met diarree. Infecties kunnen door contact met besmette
              personen, oppervlakken of voorwerpen of door het eten of drinken
              van besmette voedingswaren of water overgedragen worden.
            </p>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
